import React from "react";

function RecycleBin() {
  return (
    <>
      <div className="all-icons bin-icon icon-text">
        Recycle bin
        <br />
        (Just for show)
      </div>
    </>
  );
};

export default RecycleBin;